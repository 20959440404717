/* eslint-disable */
import React, { useState } from 'react'
import { TitleOne, TextBodyOne, Paragraph } from '../../../../components/text'
import Container from '../../../../components/layouts/container'
import { Row, Col } from 'react-flexbox-grid'
import WhyImageGif from '../../../../static/images/landing-page-one/background/why-image.webm'
import HandshakeActive from '../../../../static/icons/handshake-active.svg'
import HandshakeInActive from '../../../../static/icons/handshake-inactive.svg'
import TaskInActive from '../../../../static/icons/task-inactive.svg'
import TaskActive from '../../../../static/icons/task-active.svg'
import ProInActive from '../../../../static/icons/profesional-inactive.svg'
import ProActive from '../../../../static/icons/profesional-active.svg'
import ShieldActive from '../../../../static/icons/shield-active.svg'
import ShieldInActive from '../../../../static/icons/shield-inactive.svg'

import WhyContentStyles from './index.style'
import { useTranslation } from "react-i18next";

const WhyContent = () => {
  const { t } = useTranslation();
  const [isActiveIcon, setActiveIcon] = useState('one')
  const [degreeCircle, setDegreeCircle] = useState(0)
  const [degreeCircleIcon, setDegreeCircleIcon] = useState(0)
  const [activeAnimation, setActiveAnimation] = useState('active')
  const [circleContent, setCircleContent] = useState({
    title: t('pageHome.part19'),
    desc: t('pageHome.part20')
  })
  const iconCircleData = [
    {
      icon: {
        active: TaskActive,
        inactive: TaskInActive
      },
      title: t('pageHome.part25'),
      desc: t('pageHome.part26'),
      key: 'one'
    },
    {
      icon: {
        active: HandshakeActive,
        inactive: HandshakeInActive
      },
      title: t('pageHome.part23'),
      desc: t('pageHome.part24'),
      key: 'two'
    },
    {
      icon: {
        active: ProActive,
        inactive: ProInActive
      },
      title: t('pageHome.part19'),
      desc: t('pageHome.part20'),
      key: 'three'
    },
    {
      icon: {
        active: ShieldActive,
        inactive: ShieldInActive
      },
      title: t('pageHome.part21'),
      desc: t('pageHome.part22'),
      key: 'four'
    }
  ]
  const onPressIcon = item => {
    if (item.key === isActiveIcon) {
      return false
    }
    setActiveAnimation('')

    if (item.key === 'one') {
      setActiveIcon(item.key)
      setDegreeCircle(0)
      setDegreeCircleIcon(0)
    }
    if (item.key === 'two') {
      setActiveIcon(item.key)
      setDegreeCircle(-90)
      setDegreeCircleIcon(90)
    }
    if (item.key === 'three') {
      setActiveIcon(item.key)
      setDegreeCircle(90)
      setDegreeCircleIcon(-90)
    }
    if (item.key === 'four') {
      setActiveIcon(item.key)
      setDegreeCircle(180)
      setDegreeCircleIcon(-180)
    }

    setTimeout(() => {
      setCircleContent({
        title: item.title,
        desc: item.desc
      })
      setActiveAnimation('active')
    }, 1000)

  }
  return (
    <WhyContentStyles
      degreeIconTransform={degreeCircleIcon}
      degreeCircleTransform={degreeCircle}
      id="why-halo-jasa"
    >
      <Container className="border-box-box-sizing">
        <Row top="lg">
          <Col className="circle-animation-left">
            <TitleOne display="block">
              {t('pageHome.part16')}
            </TitleOne>
            <span className="circle-text-top"></span>
            <TextBodyOne>{t('pageHome.part17')}</TextBodyOne>
            <span className="circle-text-bottom"></span>
            <Paragraph color="ui-grey" className="why-content-desc">
              {t('pageHome.part18')}
            </Paragraph>
            <span className="circle-text-gif"></span>
            <video autoPlay loop src={WhyImageGif} />
          </Col>
          <Col className="circle-animation-right">
            <div className="why-content">
              <div className="why-content-round"></div>
              <div className="why-content-round-circle">
                {iconCircleData.map(item => {
                  return (
                    <span
                      key={item.key}
                      className={`why-content-round-circle-box ${item.key} ${item.key === isActiveIcon ? 'active' : ''
                        }`}
                    >
                      <button type="button" className="bigger-hover" onClick={() => onPressIcon(item)}>
                        <img
                          src={
                            item.key === isActiveIcon
                              ? item.icon.active
                              : item.icon.inactive
                          }
                          alt="halojasa/iconCircleData"
                          loading="lazy"
                        />
                      </button>
                    </span>
                  )
                })}
              </div>
              <div className="why-content-round-desc ">
                <div
                  className={`why-content-round-desc-text ${activeAnimation}`}
                >
                  <div>{circleContent.title}</div>
                  <Paragraph align="center" display="block">
                    {circleContent.desc}
                  </Paragraph>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </WhyContentStyles>
  )
}

export default WhyContent

/* eslint-disable */
import React from 'react'
import { TitleOne, BorderText, Paragraph } from '../../../../components/text'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Container from '../../../../components/layouts/container'
import AboutImage from '../../../../static/images/landing-page-one/background/about.webp'
import WaveAbout from '../../../../static/images/landing-page-one/background/wave-about.svg'
import AboutStyles from './index.style'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'


const About = () => {
  const { t } = useTranslation();

  return (
    <AboutStyles id="about">
      <div className="about-background"></div>
      <div className="about-waves">
        <img src={WaveAbout} alt="halojasa/wave" loading="lazy" />
      </div>
      <Container>
        <Grid>
          <Row>
            <Col xs={5} md={5} lg={5} className="about-image">
              <div className="about-content about-content--left">
                <img src={AboutImage} alt="halojasa/about-image" loading="lazy" />
              </div>
            </Col>
            <Col xs={7} md={7} lg={7} className="about-description">
              <div className="about-content about-content--right">
                <TitleOne display="block" align="left" className="about-us-title">
                  {t('pageHome.part4')}
                </TitleOne>
                <BorderText></BorderText>
                <div className="paragraph-wrap">
                  <Paragraph className="about-desc">
                    {t('pageHome.part5')}
                    <Link className="about-link" to="/bantuan/tentang-kami/"> Selengkapnya</Link>
                  </Paragraph>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </AboutStyles>
  )
}

export default About

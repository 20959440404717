/* eslint-disable */
import React from 'react'
import Container from '../../../../components/layouts/container'

import { Grid, Row, Col } from 'react-flexbox-grid'
import ServiceCategoryStyles from './index.style'
import { TitleOne, BorderText } from '../../../../components/text'
import OurServiceCard from '../../../../components/card-out-service/'

import HalloMessage from '../../../../static/images/landing-page-one/card-service/halo-massage.webp'
import HalloClean from '../../../../static/images/landing-page-one/card-service/halo-clean.webp'
import HalloAuto from '../../../../static/images/landing-page-one/card-service/halo-auto.webp'
import HalloFix from '../../../../static/images/landing-page-one/card-service/halo-fix.webp'
import BackgroundService from '../../../../static/images/landing-page-one/background/background-service-test.svg'
import ServiceLeft from '../../../../static/images/landing-page-one/background/service-left.svg'
import ServiceRight from '../../../../static/images/landing-page-one/background/service-right.svg'
import { useTranslation } from 'react-i18next'

const Category = () => {
  const { t } = useTranslation()
  return (
    <ServiceCategoryStyles id="list-service">
      <TitleOne display="block" align="center">
        {t('pageHome.part6')}
      </TitleOne>
      <div className="border-text-wrap">
        <BorderText></BorderText>
      </div>

      <div className="home-service-wrapper">
        <img
          className="img-background-service"
          src={BackgroundService}
          alt="halojasa/background-service"
          loading="lazy"
        />
        <div className="service-background-left">
          <img src={ServiceLeft} alt="halojasa/service-left" loading="lazy" />
        </div>
        <div className="service-background-right">
          <img src={ServiceRight} alt="halojasa/service-right" loading="lazy" />
        </div>
        <Container className="border-box-box-sizing">
          <Grid fluid>
            <Row>
              <Col xs={6} md={3} lg={3} className="home-service-content">
                <OurServiceCard
                  title={t('pageHome.part7')}
                  desc={t('pageHome.part8')}
                  image={HalloMessage}
                  url={'/reflexology-massage-pijat-panggilan/'}
                  altText="halojasa/massage"
                />
              </Col>
              <Col xs={6} md={3} lg={3} className="home-service-content">
                <OurServiceCard
                  title={t('pageHome.part10')}
                  desc={t('pageHome.part11')}
                  image={HalloAuto}
                  url={'/salon-mobil-pangilan/'}
                  altText="halojasa/salon_mobil"
                />
              </Col>
              <Col xs={6} md={3} lg={3} className="home-service-content">
                <OurServiceCard
                  title={t('pageHome.part12')}
                  desc={t('pageHome.part13')}
                  image={HalloFix}
                  url={'/service-ac-jakarta/'}
                  altText="halojasa/service_ac"
                />
              </Col>
              <Col xs={6} md={3} lg={3} className="home-service-content">
                <OurServiceCard
                  title={t('pageHome.part14')}
                  desc={t('pageHome.part15')}
                  image={HalloClean}
                  url={'/jasa-cleaning-service/'}
                  altText="halojasa/cleaning_service"
                />
              </Col>
            </Row>
          </Grid>
        </Container>
      </div>
    </ServiceCategoryStyles>
  )
}

export default Category
